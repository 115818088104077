<template>
  <div class="tutorial-screen-inner align-items-center">
    <div class="tutorial-screen-left">
      <img
        src="/imgs/manuelles-mapping-1.png"
        alt="Manuelles Mapping Tutorial"
      />
    </div>
    <div class="tutorial-screen-right">
      <h3 class="sub-title">{{ info.subTitle }}</h3>
      <h3 class="screen-title">{{ info.title }}</h3>
      <p class="desc mt">
        Alle Artikel, bei denen nicht eindeutig die nur fehlende EAN oder VAN
        ermittelt wurde, können - unter “Bearbeiten Manuelles Mapping“ -
        <strong>gesucht</strong>, <strong>ausgewählt</strong> und anschließend
        <strong>exportiert</strong> werden.
      </p>
      <p class="desc">
        Nach dem Export, der zu einem Download einer CSV Datei führt, stehen nur
        noch die Artikel zur Auwahl, bei denen Sie noch keinen Export
        vorgenommen haben.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        subTitle: "MANUELLES-MAPPING",
        title: "Eigene Suche des passenden Artikels"
      }
    };
  }
};
</script>

<style lang="scss">
.tutorial-screen {
  &-inner {
    padding-right: 40px;
    padding-left: 65px;
    display: flex;
  }
  &-left {
    min-width: 420px;
    img {
      width: 100%;
    }
  }
  &-right {
    margin-left: 65px;
    .screen-title {
      font-size: 30px;
      line-height: 36px;
      text-transform: uppercase;
      margin-bottom: 32px;
      color: #555555;
    }
    .sub-title {
      font-size: 18px;
      line-height: 22px;
      color: #ee7f00;
      margin-bottom: 10px;
    }
    .desc {
      font-family: Barlow;
      font-size: 16px;
      line-height: 24px;
      color: #555555;
      margin-bottom: 15px;
      strong {
        font-family: Barlow;
        font-weight: bold;
      }
      &.mt {
        margin-top: 25px;
      }
    }
  }
}
</style>